// https://medium.com/how-to-react/scroll-to-an-element-on-click-in-react-js-8424e478bb9

import React, {useEffect }  from 'react';
import{BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import { ServiceItem } from './components/Services/ServiceItem'
import ServiceElement from './components/Services/Services'
import AboutPage from './components/About/AboutPage'
import HomeElement from './components/HomeElement';
import PriceElement from './components/Prices/PriceElement'
import { PriceData } from './components/Prices/PriceData'
import FooterPage from './components/Footer/FooterPage'
import ReactGA from 'react-ga';
const TRACKING_ID = `${process.env.REACT_APP_FIREBASE_TRACKING_ID}`; // OUR_TRACKING_ID

ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  
  return (
    <div className="App">
      <>
      {/* <Navbar/> */}
      <HomeElement/> 


      
      </>

    </div>
  );
}

export default App;
