import React from 'react';
import './About.css'

function AboutPage() {


    return (
        <div id="HomeElementAbout">
            <div className="ContainerAbout">
                <div className="WrapperAbout">
                    <div className="HeaderSectionAbout">
                        <div className="RightSideAbout">
                        </div>
                        <div className="LeftSideAbout">
                        <div className="LeftAbout">

                            <h10>ARCHIVAL ROOFING CONSTRUCTION</h10>
                            <div class ="aboutbio">
                                We're not just another roofing company; we're your partners in protecting what matters most – your home. With over [X] years of experience, our team of skilled professionals is dedicated to ensuring the safety and longevity of your roof.
                            </div>
                            <div class ="aboutbio">
                                We offer top-quality roofing solutions, from repairs and inspections to complete roof replacements. Our commitment to excellence means we use the highest quality materials and the latest industry techniques to provide you with a roof that withstands the test of time and weather.
                            </div>
                            <div class ="aboutbio">
                                What sets us apart? It's our passion for customer satisfaction. We work closely with you, addressing your unique needs and budget. We provide transparent quotes, timely project completion, and unmatched craftsmanship.
                            </div>
                            <div class ="aboutbio">
                                When you choose [Your Roofing Company Name], you're choosing peace of mind. Protect your investment and keep your family safe. Reach out to us today for roofing solutions you can trust.
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutPage