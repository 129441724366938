import React, { Component } from "react";
import emailjs from 'emailjs-com';
import './ContactPage.css';
import styled from "styled-components";
import './HomeElement.css';

export const Wrapper = styled.div``;

export const ContactPageHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: clamp(3rem, 2.5vw, 2rem); 
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  color: black;
`;

const regularExpression = RegExp(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/);

const EMAILJS_SERVICEID = `${process.env.REACT_APP_EMAILJS_SERVICEID}`;
const EMAILJS_TEMPLATEID = `${process.env.REACT_APP_EMAILJS_TEMPLATEID}`;
const EMAILJS_USERID = `${process.env.REACT_APP_EMAILJS_USERID}`;

const validation = ({ error, ...rest }) => {
  let checkValidation = true;

  Object.values(error).forEach(val => {
    if (val.length > 0) {
      checkValidation = false;
    }
  });

  Object.values(rest).forEach(val => {
    if (val === "" || val === null) {
      checkValidation = false;
    }
  });

  return checkValidation;
};

export default class HomeElement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      first: '',
      last: '',
      email: '',
      number: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      ad: '',
      error: {
        first: '',
        last: '',
        email: '',
        number: '',
        address: '',
        city: '',
        state: '',
        zip: '',
      }
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  onFormSubmit = event => {
    event.preventDefault();

    emailjs.sendForm(
      EMAILJS_SERVICEID,
      EMAILJS_TEMPLATEID,
      event.target,
      EMAILJS_USERID
    );

    if (validation(this.state)) {
      alert("Your message was sent successfully!");
      console.log(this.state);
    } else {
      console.log("Error occurred");
    }
    event.target.reset();
  };

  formObject = event => {
    event.preventDefault();

    const { name, value } = event.target;
    let error = { ...this.state.error };

    switch (name) {
      case "first":
        error.first = value.length < 2 ? "Enter your First Name" : "";
        break;
      case "last":
        error.last = value.length < 2 ? "Enter your Last Name" : "";
        break;
      case "email":
        error.email = regularExpression.test(value) ? "" : "Email is not valid";
        break;
      case "number":
        error.number = value.length < 10 ? "Should be 10 digits" : "";
        break;
      case "address":
        error.address = value.length < 5 ? "Address should be at least 5 characters long" : "";
        break;
      case "city":
        error.city = value.length < 2 ? "City should be at least  characters" : "";
        break;
      case "state":
        error.state = value.length < 2 ? "State should be at least 2 characters" : "";
        break;
      case "zip":
        error.zip = value.match(/^\d{5}$/) ? "" : "ZIP Code must be 5 digits";
        break;
      default:
        break;
    }

    this.setState({
      error,
      [name]: value,
    });
  };

  render() {
    const { error } = this.state;

    return (
      <div id="HomeElement">
        <div className="Container">
          <div className="Wrapper">
            <div className="HeaderSection">
              <div className="RightSide">
                <div className="SectionRight">

                </div>

              </div>
              <div className="LeftSide">
                <div className="Section">
                  <Wrapper>
                    <ContactPageHeader>
                      <Header>Contact Us</Header>
                    </ContactPageHeader>
                  </Wrapper>
                  <form className="contact-form" onSubmit={this.onFormSubmit}>
                    <div className="ContactContainer">
                      <div className="contact-box">
                        <div className="input-row">
                          <div className="input-group">
                            <label>First Name *</label>
                            <input
                              type="text"
                              name="first"
                              placeholder="Mary"
                              required
                              onChange={this.formObject}
                            />
                            {error.first && <span className="invalid-feedback">{error.first}</span>}
                          </div>
                          <div className="input-group">
                            <label>Last Name *</label>
                            <input
                              type="text"
                              name="last"
                              placeholder="Jane"
                              required
                              onChange={this.formObject}
                            />
                            {error.last && <span className="invalid-feedback">{error.last}</span>}
                          </div>
                        </div>
                        <div className="input-row">
                          <div className="input-group">
                            <label>Email Address *</label>
                            <input
                              type="email"
                              name="email"
                              placeholder="me@email.com"
                              required
                              onChange={this.formObject}
                            />
                            {error.email && <span className="invalid-feedback">{error.email}</span>}
                          </div>
                          <div className="input-group">
                            <label>Phone Number *</label>
                            <input
                              type="tel"
                              name="number"
                              placeholder="1234567890"
                              required
                              onChange={this.formObject}
                            />
                            {error.number && <span className="invalid-feedback">{error.number}</span>}
                          </div>
                        </div>
                        <div className="input-group">
                          <label>Address *</label>
                          <input
                            type="text"
                            name="address"
                            placeholder="1234 Mary Ln"
                            required
                            onChange={this.formObject}
                          />
                          {error.address && <span className="invalid-feedback">{error.address}</span>}
                        </div>
                        <div className="input-row">
                          <div className="input-group">
                            <label>City *</label>
                            <input
                              type="text"
                              name="city"
                              placeholder="Dallas"
                              required
                              onChange={this.formObject}
                            />
                            {error.city && <span className="invalid-feedback">{error.city}</span>}
                          </div>
                          <div className="input-group">
                            <label>State *</label>
                            <input
                              type="text"
                              name="state"
                              placeholder="TX"
                              required
                              onChange={this.formObject}
                            />
                            {error.state && <span className="invalid-feedback">{error.state}</span>}
                          </div>
                          <div className="input-group">
                            <label>ZIP Code *</label>
                            <input
                              type="text"
                              name="zip"
                              placeholder="98765"
                              required
                              onChange={this.formObject}
                            />
                            {error.zip && <span className="invalid-feedback">{error.zip}</span>}
                          </div>
                        </div>
                        <div className="wrapper">
                          <button className="fade" type="submit">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className = "details">
            <div className="Info">
              <div className="companyName">
                Fouch Properties
              </div>
              <div className ="about">
              <div className ="something">
              <div className="descRight">
                We specialize in helping homeowners sell their houses quickly and hassle-free. As experienced
                real estate wholesalers, we provide a fast and straightforward solution for individuals looking
                to offload properties without the stress of traditional selling methods.
                </div>

                <div className="descLeft">
                  Whether you’re dealing with an unwanted property, financial challenges, or simply need a quick sale,
                  we offer competitive cash offers and a smooth process to meet your needs. No repairs, no agent fees, and no waiting—just a reliable way to sell your house on your terms.
                </div>

                </div>
  
              </div>
                {/* <div className="margin">
                  Let us help you move forward with ease and confidence. We're here to turn your property problems into opportunities for a fresh start!
                </div> */}
            </div>
            <div className="Social"></div>
          </div>
          </div>
        </div>
      </div>
    );
  }
}
